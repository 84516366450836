import React, { Component } from 'react'
import axios from 'axios'
import Config from '../../Config'
import InlineLoader from '../../utilities/InlineLoader'
import DatePicker from 'react-datepicker';
import iZitoast from 'izitoast'
import Select from 'react-select'
import CryptoJS from 'crypto-js';
 
export default class SubmitInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            InvoiceData: [],
            currentPageData: [],
            ProviderDetails:{},
            PageIndex: 1,
            PageSize: 10,
            currentSortCol: 'dateOfService',
            sortOrder: 'DESC',
            InvoiceDataLoaded: true,
            SearchBy: 'DOS',
            StartDate: null,
            EndDate: null,
            ReferralNo: '',
            ServiceType: 'TR',
            encryptedData:''
        }

        this.handleSearchByChange = this.handleSearchByChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleReferralNoChange = this.handleReferralNoChange.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);
        this.selectService = this.selectService.bind(this);
        this.setPageSize = this.setPageSize.bind(this);
        this.setPageIndex = this.setPageIndex.bind(this);
        this.sortData = this.sortData.bind(this);
    }
    
    componentDidMount() {
       
    }

    handleClearClick(event) {
        this.setState({
            SearchBy: 'DOS', StartDate: null, EndDate: null, ReferralNo: '', ServiceType: 'TR', InvoiceDataLoaded: true, InvoiceData:[], currentPageData: [], ProviderDetails: {}
        });        
    }

    selectService(selectedOption) {
        const selectedValue = selectedOption.value;
        this.setState({ ServiceType: selectedValue});
    };

    handleSearchByChange(event) {
        let searchBy = event.target.checked ? (event.target.id === 'rbDateOfService' ? 'DOS' : 'PO') : null
        this.setState({
            SearchBy: searchBy,
            StartDate: searchBy === 'DOS' ? this.state.StartDate : null,
            EndDate: searchBy === 'DOS' ? this.state.EndDate : null,
            ReferralNo: searchBy === 'PO' ? this.state.ReferralNo : '',
        });
    }

    handleStartDateChange(event) {
        this.setState({ StartDate: event });
    }
    handleEndDateChange(event) {
        this.setState({ EndDate: event });
    }

    handleReferralNoChange(event) {
        if (this.ValidatePO(event.target.value)) {
            this.setState({ ReferralNo: event.target.value});
        }
        else {
            return false;
        }
    }

    handleSearchClick(event) {
        let startDate = this.state.StartDate;
        let endDate = this.state.EndDate;
        let referralNo = this.state.ReferralNo.trim();
        let serviceType = this.state.ServiceType.trim();
        let errorMsg = '';
        if (this.state.SearchBy === 'DOS') {
            if (startDate == null)
                errorMsg = 'Please select start date';
            else if (endDate == null)
                errorMsg = 'Please select end date';
            else if (new Date(startDate) > new Date(endDate))
                errorMsg = 'Please verify start date and end date. Start date should be less than end date';
        }
        else if (this.state.SearchBy === 'PO' && referralNo === '') {
            errorMsg = 'Please enter referral number';
        }
    
        var toast = document.querySelector('.searchError');
        if (toast != null)
            iZitoast.hide({ transitionOut: 'fadeOutUp' }, toast);

        if (errorMsg.trim() !== '') {
            iZitoast.error({ class: 'searchError', message: errorMsg.trim() });
            return false;
        }
        else {
            startDate = new Date(startDate);
            endDate = new Date(endDate);
        }

        this.setState({
            InvoiceData: [],
            InvoiceDataLoaded: false
        });
        axios.get(Config.API_URL + '/SubmitInvoice/GetDataForSubmitInvoice', {
            params: {
                startDate: startDate === null ? null : ((startDate.getMonth() + 1) + '-' + startDate.getDate() + '-' + startDate.getFullYear()),
                endDate: endDate === null ? null : ((endDate.getMonth() + 1) + '-' + endDate.getDate() + '-' + endDate.getFullYear()),
                serviceType: serviceType === "" ? null : serviceType,
                referralNo: referralNo === "" ? 0 : referralNo
            }
        })
            .then(res => {           
                const {pendingInvoiceDetails,...providerDetails}=res.data;
                this.setState({
                    InvoiceData: pendingInvoiceDetails,
                    ProviderDetails:providerDetails,
                    InvoiceDataLoaded: true
                }, () => this.setPageData(this.state.InvoiceData))
            })
    }

    ValidatePO(PO) {
        let reg = /^[0-9]*$/;
        if (!reg.test(PO)) {
            return false;
        }
        else {
            return true;
        }
    }

    openSubmitInvoicePopup(item, key) {

        let url;

        if (item.serviceType === "TR") {
            url = Config.SubmitInvoiceTRLink;
        } else {
            url = Config.SubmitInvoiceTLLink;
        }

        let dataToEncrypt;

        if(item.serviceType==="TR"){            
             dataToEncrypt = [
                `providerID=${this.state.ProviderDetails.providerID}`,
                `providerName=${this.state.ProviderDetails.providerName}`,
                `providerPhone=${this.state.ProviderDetails.providerPhone}`,
                `providerEmail=${this.state.ProviderDetails.providerEmail}`,
                `dos=${item.dateOfService}`,
                `appointmentNumber=${item.appointmentNumber}`,                                           
                `injuredWorker=${item.injuredWorker}`,
                `serviceType=${item.serviceProvided}`,  
                `appointmentStatus=${item.appointmentStatus}`,
                `flatRate=${item.flatRate}`,
                `perMileRate=${item.flatRate == null || item.flatRate == 0 ? item.perMileRate : null}`,
                `waitTimeApproved=${item.waitTimeApproved}`,
                `trWaitTimeStartTime=${item.trWaitTime_StartTime}`,
                 `waitTimeRate=${item.waitTimeRate}`,
                 `contractedMinimumMileage=${item.flatRate == null || item.flatRate == 0 ? item.contractedMinimumMileage : null}`,
                 `isOtherFeeAdded=${item.isOtherFeeAdded}`
            ];
        }
        else if(item.serviceType==="TL"){
             dataToEncrypt = [
                `dos=${item.dateOfService}`,
                `appointmentNumber=${item.appointmentNumber}`,
                `injuredWorker=${item.injuredWorker}`,
                `appointmentStatus=${item.appointmentStatus}`,
                `interpreterName=${item.interpreterName}`,
                `certificationNumber=${item.certificationNumber}`,
                `interpreterAddress=${item.interpreterAddress}`,
                `startTime=${item.startTime}`, 
                `endTime=${item.endTime}`,     
                `interpretationRate=${item.interpretationRate}`,
                `perMileRate=${item.perMileRate}`,
                `tlOtherRate=${item.tlOtherRate}`,
                `providerID=${this.state.ProviderDetails.providerID}`,
                `providerName=${this.state.ProviderDetails.providerName}`,
                `providerPhone=${this.state.ProviderDetails.providerPhone}`,
                 `providerEmail=${this.state.ProviderDetails.providerEmail}`,
                 `isOtherFeeAdded=${item.isOtherFeeAdded}`,
                 `isTLCertifiedSubService=${item.isTLCertifiedSubService}`
            ];      
        }

        let paramsString=dataToEncrypt.join('&');

        const encrypted = CryptoJS.AES.encrypt(paramsString, CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_KEY), { iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV), mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });

        const encryptedString = encrypted.toString();

        this.setState({ encryptedData: encryptedString }, () => {           
            const urlWithParams = `${url}?params=${encodeURIComponent(this.state.encryptedData)}`;
            window.open(urlWithParams, "_blank");
        });
    }

    setPageSize(e) {
        let maxPageIndex = Math.ceil(this.state.InvoiceData.length / e.target.value);
        this.setState({
            PageSize: e.target.value,
            PageIndex: this.state.PageIndex > maxPageIndex ? maxPageIndex : this.state.PageIndex
        }, () => this.setPageData(this.state.InvoiceData));
    }
    setPageIndex(e) {
        this.setState({
            PageIndex: parseInt(e.target.name)
        }, () => this.setPageData(this.state.InvoiceData));
    }
    setPageData(data) {
        this.setState({
            currentPageData: data.slice(this.state.PageSize * this.state.PageIndex - this.state.PageSize, this.state.PageSize * this.state.PageIndex)
        })
    }
    sortData(Index, DType) {
        let sortedData = [];

        sortedData = [].concat(this.state.InvoiceData)
            .sort((a, b) => sortEquation(a, b, this.state.sortOrder))
            .map((item, i) => { return item; }
            );
        let newOrder = this.state.sortOrder == 'DESC' ? "ASC" : 'DESC';
        this.setState({
            InvoiceData: sortedData,
            sortOrder: newOrder,
            currentSortCol: Index
        }, () => this.setPageData(this.state.InvoiceData))

        function sortEquation(a, b, sortOrder) {
            if (DType == 'number') {
                return sortOrder == 'DESC' ? parseInt(a[Index]) - parseInt(b[Index]) : parseInt(b[Index]) - parseInt(a[Index]);
            }
            else if (DType == 'date') {
                return sortOrder == 'DESC' ? dateToInt(a[Index]) - dateToInt(b[Index]) : dateToInt(b[Index]) - dateToInt(a[Index]);
            }
            else if (DType == 'string') {
                return (sortOrder == 'DESC' ? a[Index] > b[Index] : b[Index] > a[Index]) ? 1 : -1;
            }
        }
        function dateToInt(date) {
            let [m, d, y] = date.split('/');
            if (isNaN(parseInt(y + m + d))) {
                return -1;
            }
            return parseInt(y + m + d);
        }
    }
    
     options = [
        { value: 'TR', label: 'Transportation' },
        { value: 'TL', label: 'Translation' }
      ]

    
    renderPagination() {
        let maxPageIndex = Math.ceil(this.state.InvoiceData.length / this.state.PageSize);
        let pageButtons = []
        let startIndex = 1;
        let lastIndex = 5;
        if (this.state.PageIndex > 3) {
            if (this.state.PageIndex + 2 <= maxPageIndex) {
                startIndex = this.state.PageIndex - 2;
                lastIndex = Math.min(this.state.PageIndex + 2, maxPageIndex)
            }
            else {
                startIndex = Math.max(maxPageIndex - 4, 1);
                lastIndex = maxPageIndex;
            }
        }
        for (startIndex; startIndex <= Math.min(lastIndex, maxPageIndex); startIndex++) {
            pageButtons.push(<li key={startIndex}><a onClick={this.setPageIndex} name={startIndex} className={startIndex == this.state.PageIndex ? "active" : ""}>{startIndex}</a></li>)
        }
        return (
            <div>
                <div className="grid-bottom row">
                    <div className="col-lg">
                        <div className="show-record">
                            <select onChange={this.setPageSize} name="PageSize" value={this.state.PageSize} className="custom-select" style={{ height: "38px" }}>
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            <span className="form-label">Records per page</span>
                        </div>
                    </div>
                    <div className="col-lg">

                        <div className="paginetion float-right">
                            {maxPageIndex != 1 && <ul style={{ cursor: "pointer" }}>
                                {maxPageIndex > 5 && (this.state.PageIndex != 1 ? <li><a onClick={this.setPageIndex} name={1} className="prevar first"></a></li> : <li><a style={{ cursor: "default" }} className="prevar first opa-4"></a></li>)}
                                {maxPageIndex > 5 && (this.state.PageIndex != 1 ? <li><a onClick={this.setPageIndex} name={this.state.PageIndex - 1} className="prevar"></a></li> : <li><a style={{ cursor: "default" }} className="prevar opa-4"></a></li>)}
                                {pageButtons.map(i => { return i; })}
                                {maxPageIndex > 5 && (this.state.PageIndex != maxPageIndex ? <li><a onClick={this.setPageIndex} name={this.state.PageIndex + 1} className="nxtar"></a></li> : <li><a style={{ cursor: "default" }} className="nxtar opa-4"></a></li>)}
                                {maxPageIndex > 5 && (this.state.PageIndex != maxPageIndex ? <li><a onClick={this.setPageIndex} name={maxPageIndex} className="nxtar last"></a></li> : <li><a style={{ cursor: "default" }} className="nxtar last opa-4"></a></li>)}
                            </ul>}

                            {/* <div style={{ textAlign: "right" }}>{this.state.InvoiceData.length + ' records, in ' + maxPageIndex + ' page' + (maxPageIndex > 1 ? 's' : '')}</div> */}
                        </div>
                        <div className="float-right" style={{ margin: "11px 25px" }}>
                            {this.state.PageIndex * this.state.PageSize - this.state.PageSize + 1} - {Math.min(this.state.PageIndex * this.state.PageSize, this.state.InvoiceData.length)} of {this.state.InvoiceData.length}
                        </div>
                    </div>
                </div>

            </div>);
    }
    render() {
        const customStyles = {
            control: base => ({
              ...base,
              height: 46,
              minHeight: 46
            })
          };          

          const selectedOption = this.options.find(option => option.value === this.state.ServiceType);
        return (
            <div>
                <main className="main-content">
                    <div className="container-fluid">
                        {Config.AlertMessage &&
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="alert alert-warning">
                                        <strong>***{Config.AlertMessage}</strong>
                                    </div>
                                </div>
                            </div>}
                        <div className="row">
                            <div className="col-xl-7 col-lg-6 pb-3">
                                <div className="title">
                                    <h1>Submit an Invoice</h1>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6">
                                <div className="alert alert-danger">
                                    <strong>***Please allow up to 72 hours to reflect any recently submitted invoices</strong>
                                    <p className="pt-2 pb-0"><strong>***Payment Information prior to Date of Service 01/21/2020 will not be visible in this portal</strong></p>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-1">
                            <div className='col-md-12 col-lg-12'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className="form-group">
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="rbDateOfService" name="searchBy" className="custom-control-input" onChange={this.handleSearchByChange}
                                                            checked={this.state.SearchBy === 'DOS' ? true : false} />
                                                        <label className="custom-control-label" htmlFor="rbDateOfService">Date of Service</label>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="rbReferralNo" name="searchBy" className="custom-control-input" onChange={this.handleSearchByChange}
                                                            checked={this.state.SearchBy === 'PO' ? true : false} />
                                                        <label className="custom-control-label" htmlFor="rbReferralNo">PO#</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row col-12' style={{marginLeft:"0"}}>
                                                <div className="col-xl-3 col-lg-6" style={{ display: this.state.SearchBy === 'DOS' ? 'block' : 'none'}}>
                                                    <div className="form-group">
                                                        <div className="custom-react-datepicker">
                                                            <DatePicker
                                                                selected={this.state.StartDate}
                                                                onChange={this.handleStartDateChange}
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                className="form-control"
                                                                placeholderText="Start Date"
                                                                onKeyDown={e => e.preventDefault()}
                                                                popperPlacement="bottom-end"
                                                                popperModifiers={{
                                                                    flip: {
                                                                        enabled: false
                                                                    },
                                                                    preventOverflow: {
                                                                        enabled: true,
                                                                        escapeWithReference: false
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-lg-6" style={{ display: this.state.SearchBy === 'DOS' ? 'block' : 'none', marginBottom:"42px" }}>
                                                    <div className="form-group">
                                                        <div className="custom-react-datepicker">
                                                            <DatePicker
                                                                selected={this.state.EndDate}
                                                                onChange={this.handleEndDateChange}
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                className="form-control"
                                                                placeholderText="End Date"
                                                                onKeyDown={e => e.preventDefault()}
                                                                popperPlacement="bottom-end"
                                                                popperModifiers={{
                                                                    flip: {
                                                                        enabled: false
                                                                    },
                                                                    preventOverflow: {
                                                                        enabled: true,
                                                                        escapeWithReference: false
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-xl-3" style={{ display: this.state.SearchBy === 'PO' ? 'block' : 'none', marginBottom:"42px" }}>
                                                    <div className="form-group refferalInput">
                                                        <input type="text" id="txtReferralNo" maxLength="9" className="form-control" placeholder="Referral No" onChange={this.handleReferralNoChange}
                                                            value={this.state.ReferralNo} />
                                                    </div>
                                                </div>

                                                
                                    <div className='col-xl-2 col-lg-12' style={{marginTop:"-43px", marginBottom:"20px"}}>
                                        <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Service Type:</p>
                                        <div style={{marginTop:'5px', maxWidth:'235px'}}>
                                            <Select options={this.options} onChange={this.selectService} value={selectedOption} styles={customStyles}/>                                               
                                        </div>

                                    </div>
                                
                                    <div className="col-xl-3 col-lg-12" style={{zIndex:'0'}}>
                                                    <div className="form-group" style={{position:'relative', zIndex:'-1'}}>
                                                        <button type="submit" id="btnSearch" className="btn btn-primary mr-2" onClick={this.handleSearchClick}><span>Search</span></button>
                                                        <button type="submit" id="btnClear" className="btn btn-secondary" onClick={this.handleClearClick}><span>Clear</span></button>

                                                    </div>
                                                </div>        
                                            </div>                                                        
                                </div>
                            </div>
                        </div> 


                        {/* <!-- Grid Table =====================================--> */}
                        <div className="table-responsive grid-table">

                                <table className="table table-hover table-border">
                                    <thead>
                                        <tr>
                                            <th onClick={() => this.sortData('appointmentNumber', 'number')} style={{ cursor: "pointer" }}>
                                                PO#
                                                {this.state.currentSortCol == "appointmentNumber" && this.state.sortOrder == 'DESC' && <span>&nbsp;<img src="images/rectangle-down.svg" width="11px" height="14px" alt="" /></span>}
                                                {this.state.currentSortCol == "appointmentNumber" && this.state.sortOrder == 'ASC' && <span>&nbsp;<img src="images/rectangle-up.svg" width="11px" height="14px" alt="" /></span>}
                                            </th>
                                            <th onClick={() => this.sortData('dateOfService', 'date')} style={{ cursor: "pointer" }} >
                                                Date of service
                                                {this.state.currentSortCol == "dateOfService" && this.state.sortOrder == 'DESC' && <span>&nbsp;<img src="images/rectangle-down.svg" width="11px" height="14px" alt="" /></span>}
                                                {this.state.currentSortCol == "dateOfService" && this.state.sortOrder == 'ASC' && <span>&nbsp;<img src="images/rectangle-up.svg" width="11px" height="14px" alt="" /></span>}
                                            </th>
                                            <th onClick={() => this.sortData('serviceProvided', 'string')} style={{ cursor: "pointer" }}>
                                                Service provided
                                                {this.state.currentSortCol == "serviceProvided" && this.state.sortOrder == 'DESC' && <span>&nbsp;<img src="images/rectangle-down.svg" width="11px" height="14px" alt="" /></span>}
                                                {this.state.currentSortCol == "serviceProvided" && this.state.sortOrder == 'ASC' && <span>&nbsp;<img src="images/rectangle-up.svg" width="11px" height="14px" alt="" /></span>}
                                            </th>
                                            <th onClick={() => this.sortData('injuredWorker', 'string')} style={{ cursor: "pointer" }}>
                                                Injured worker
                                                {this.state.currentSortCol == "injuredWorker" && this.state.sortOrder == 'DESC' && <span>&nbsp;<img src="images/rectangle-down.svg" width="11px" height="14px" alt="" /></span>}
                                                {this.state.currentSortCol == "injuredWorker" && this.state.sortOrder == 'ASC' && <span>&nbsp;<img src="images/rectangle-up.svg" width="11px" height="14px" alt="" /></span>}
                                            </th>
                                            <th align="center" className="text-center">Submit Invoice</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.currentPageData.length > 0 && this.state.currentPageData.map((item, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{item.appointmentNumber}</td>
                                                    <td>{item.dateOfService}</td>
                                                    <td>{item.serviceProvided}</td>
                                                    <td>{item.injuredWorker}</td>
                                                    <td align="center" width="10">
                                                        {item.canSubmitInvoice == 1 && <button type="button" name={item.serviceType} onClick={() => { this.openSubmitInvoicePopup(item,key); }} className="btn btn-primary btn-md"><span>Submit</span></button>}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            {this.state.InvoiceDataLoaded && this.state.currentPageData.length == 0 && <div className="pd-10-12"><b></b>No records found...</div>}
                            {!this.state.InvoiceDataLoaded && <InlineLoader></InlineLoader>}
                        </div>
                        {this.state.currentPageData.length != 0 && this.renderPagination()}
                    </div>
                </main>
            </div>
        )
    }
}
